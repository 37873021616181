import fetch from "isomorphic-fetch"
import { setContext } from "@apollo/link-context"
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import possibleTypes from "../graphql/lifespring-votes-possible-types.json"

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      "X-Hasura-Admin-Secret": "lifespring-votes-585",
      // authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const httpLink = createHttpLink({
  uri: "https://db3.castner.dev/v1/graphql",
  fetch,
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes,
  }),
  link: authLink.concat(httpLink),
})

export default client
