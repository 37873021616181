module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"DSN":"https://a100aa02871a48218921fce7ee647fa3@o396734.ingest.sentry.io/5259360"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lifespring Votes","short_name":"Lifespring Votes","start_url":"/","background_color":"#fff","theme_color":"#647c96","display":"minimal-ui","icon":"src/images/lifespring-logo-dark.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6ca40b64b5f530b50c988dea11252a00"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
